import React, { useEffect } from 'react'
import Header from './Header'
import { Link,useNavigate} from 'react-router-dom';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { Footer } from './Footer';
import { useDispatch } from 'react-redux';
import { setLoginStatusNull, userRecords } from '../Slice/QuestionSlice';
import { Bar, Pie, Scatter,} from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, RadialLinearScale, PointElement, LineController, LineElement, LinearScale, BarElement } from "chart.js";
import { CategoryScale } from 'chart.js'; 
import { ToastContainer, toast } from 'react-toastify';


function Index() {
    const {loginstatus,records} = useSelector((state) => state.questionOperation);
    const navigate = useNavigate();
    console.log(localStorage);
    console.log(loginstatus);

    const localUser = JSON.parse(localStorage.getItem("user"));
    const sessionUser = JSON.parse(sessionStorage.getItem("user"));

    let student_id = '';

    if(localUser && localUser.name!==""){
        student_id = localUser.id;
    }

    else if(sessionUser && sessionUser.name!==""){
        student_id = sessionUser.id;
    }

    const dispatch = useDispatch();

    console.log("new student id: ",student_id);

    useEffect(()=>{
        if(student_id!=="")
        {
            dispatch(userRecords(student_id));
        }
        if (loginstatus === 'success') {
            toast.success("Login Success",{
                toastId:'7'
            });

            dispatch(setLoginStatusNull())
        }
    },[student_id]);

    var correct  = records.reduce((accumulator, record) => accumulator + parseInt(record.correct_ans), 0);

    var incorrect  = records.reduce((accumulator, record) => accumulator + parseInt(record.incorrect), 0);

    var unattempted  = records.reduce((accumulator, record) => accumulator + parseInt(record.unattempted), 0);

      const data = {
        labels: [
          'Correct',
          'Incorrect',
          'Unattempted'
        ],
        datasets: [{
          label: 'My First Dataset',
          data: [correct, incorrect, unattempted],
          backgroundColor: [
            'rgb(51, 167, 14)',
            'rgb(255, 5, 5)',
            'rgb(54, 138, 222)'
          ],
          hoverOffset: 4
        }]
      };

      const config = {
        type: 'pie',
        data: data,
      };

      const data1 = {
        labels: [
            'Correct',
            'Incorrect',
            'Unattempted'
        ],

        datasets: [{
          type: 'bar',
          label: 'Bar Dataset',
          data: [correct, incorrect, unattempted],
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(207, 96, 243,0.3)'
        },
         {
          type: 'line',
          label: 'Line Dataset',
          data: [correct, incorrect, unattempted],
          fill: false,
          borderColor: 'rgb(54, 162, 235)'
        }]
      };

      const config1 = {
        type: 'scatter',
        data: data1,
        options: {
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      };


    ChartJS.register(ArcElement,CategoryScale,BarElement,Tooltip,Legend,RadialLinearScale,PointElement,LineController, LineElement, PointElement, LinearScale);

    if(student_id === ''){
        return (
        <>
        {navigate('/login')}
        </>
     )
    }

    else {
    return (
        <div>
            <Header/>
            <ToastContainer />
            <div class="container" id="wrapper-content">
                <div class="row" id="demo-version">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-header bg-warning bg-opacity-10 p-3">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div className='m-auto'>
                                        {localUser ? (
                                            localUser && localUser.name !== "" ? (
                                                <h6 class="alert-heading text-warning">
                                                <i class="bi bi-person me-2"></i> Welcome {localUser.name}
                                                </h6>
                                            ) : (
                                            (window.location.href = "/login")
                                            )
                                        ) : sessionUser && sessionUser.name !== "" ? (
                                            <h6 class="alert-heading text-warning">
                                            <i class="bi bi-person me-2"></i> Welcome {sessionUser.name}
                                            </h6>
                                        ) : (
                                            (window.location.href = "/login")
                                        )
                                        }
                                        <p class="alert-message fw-bold">Custom Quiz Is Available</p>
                                        <p class="alert-message mb-0">Select your subjects and start the quiz. </p>
                                    </div>
                                    <div>
                                        <Link to="/select-course" class="btn btn-warning btn-sm shadow-none"> Start New Quiz</Link>
                                    </div>
                                </div>
                            </div>

                            <div class="card-body">

                                <div class="row">
                                    <div class="col-sm-4">
                                        <div class="d-flex">
                                            <div class="card card-body mb-2 border-0 bg-success bg-opacity-10 text-success">
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <p class="mb-0">No. Of Courses</p>
                                                    <h2 class="mb-0">25</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-4">
                                        <div class="d-flex">
                                        <div class="card card-body mb-2 border-0 bg-danger bg-opacity-10 text-danger">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <p class="mb-0">Unanswered</p>
                                        <h2 class="mb-0">25</h2>
                                    </div>
                                    </div>
                                    </div>
                                    </div>

                                    <div class="col-sm-4">
                                        <div class="d-flex">
                                        <div class="card card-body border-0 bg-primary bg-opacity-10 text-primary">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <p class="mb-0">Unseen</p>
                                        <h2 class="mb-0">25</h2>
                                    </div>
                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row gx-2 mb-2"> 
                
                    <div class="col-sm-7">
                        <div class="card h-100 plaincard">
                            <div class="card-header text-muted">
                                Performance
                            </div>
                            <div class="card-body">
                                <div class="row gx-1">
                                    <div class="col-sm-4 m-auto">
                                    <Pie data={data} options={config}/> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="card plaincard h-100">
                            <div class="card-header text-muted">
                                Question Status
                            </div>
                            <div class="card-body mt-4">
                                <div class="card card-body mb-3 border-0 bg-success bg-opacity-10 text-success">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <p class="mb-0">Correct</p>
                                        <h2 class="mb-0">{correct}</h2>
                                    </div>
                                </div>
                                <div class="card card-body mb-3 border-0 bg-danger bg-opacity-10 text-danger">
                                    <div class="d-flex justify-content-between align-items-center">
                                    <p class="mb-0">Incorrect</p>
                                        <h2 class="mb-0">{incorrect}</h2>
                                    </div>
                                </div>
                                <div class="card card-body border-0 bg-primary bg-opacity-10 text-primary">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <p class="mb-0">Unanswered</p>
                                        <h2 class="mb-0">{unattempted}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12">
                        <div class="card plaincard">
                            <div class="card-header text-muted">Quizzes</div>
                            <div class="card-body">
                                <div class="row gx-2 table-head">
                                    <div class="col-sm">Name</div>
                                    <div class="col-sm-1">Course Name</div>
                                    <div class="col-sm-1">No. of Q's.</div>
                                    <div class="col-sm-1">Correct</div>
                                    <div class="col-sm-1">Incorrect</div>
                                    <div class="col-sm-1">Unanswered</div>
                                    <div class="col-sm">Created</div>
                                </div>

                                {records && records.map((e) => (
                                <div class="row gx-2 table-row align-items-center">
                                    <div class="col-sm"><b>[Test] </b> {e.std_name}</div>
                                    <div class="col-sm-1">{e.cname}</div>
                                    <div class="col-sm-1">{e.total_questions}</div>
                                    <div class="col-sm-1">{e.correct_ans}</div>
                                    <div class="col-sm-1">{e.incorrect}</div>
                                    <div class="col-sm-1">{e.unattempted}</div>
                                    <div class="col-sm">{e.cdate}</div>
                                </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

// else{
//     return 
//     (
//         <>
//         {navigate('/login')}
//         </>
//     )
// }
// else{
//     return (
//         <div  style={{marginTop:'150px',verticalAlign:'middle'}}>
//             <img src="../images/info.gif" className="dashboard-logo" loading='lazy'/>
//         </div>
//     )
// }

}

export default Index