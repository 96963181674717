import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { setEmail,setPassword,login,setLogCheck,setTypePassword,setTypeText} from '../Slice/QuestionSlice';
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Icon, { Icons } from 'react-icons-kit';
import { Footer } from './Footer';

function UserLogin() {

    const {email,password,printtoast,loginstatus,checked,setType,setIcon} = useSelector((state) => state.questionOperation);

    const dispatch=useDispatch();

    const handleLogin=()=>{
        var data = {      
            email:email,
            password:password
          };
          dispatch(login(data));
          console.log(loginstatus)
    }

    const handleToggle = () => {
        if (setType==='password') 
        {
            dispatch(setTypeText())
        } 
        else 
        {
            dispatch(setTypePassword())
        }
    }

    useEffect(()=>{
        if (loginstatus === 'fail') {
            toast.error("Wrong Credentials",{
                toastId:'01'
            });
        }
    },[loginstatus]);
       
    return (
        <div>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12 px-0">
                        <nav class="navbar navbar-expand-lg bgheader">
                            <div class="container">
                                <a class="navbar-brand d-flex align-items-end d-lg-block" href="index.html">
                                    <img src="images/gayatrilogo.png" style={{height:'100px'}} alt="logo" />
                                </a>
                                <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                                    <span class="navbar-toggler-icon"></span>
                                </button>
                                <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar"
                                    aria-labelledby="offcanvasNavbarLabel">
                                    <div class="offcanvas-header">
                                        <h5 class="offcanvas-title" id="offcanvasNavbarLabel">Navigation</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="offcanvas"
                                            aria-label="Close"></button>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>

            <div class="container my-1 my-lg-5">
                <div class="row align-items-center">
                    <div class="col-sm-9 m-auto" style={{textAlign:"left"}}>
                        <div class="card border-0 shadow">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-6 py-3  px-5  d-none d-sm-none d-lg-block">
                                        <img src="images/login.svg" class="img-fluid" />
                                    </div>
                                    <div class="col-sm-6 p-5">
                                        <h3 class="fw-bold mb-4">Sign In</h3>
                                        <div class="form-container">
                                            <div class="mb-3">
                                                <label class="form-label">Username <span class="text-danger">*</span></label>
                                                <input type="text" placeholder="Username" class="form-control" onChange={(e)=>dispatch(setEmail(e.target.value))}/>
                                            </div>
                                            <div class="mb-3">
                                                <label class="form-label">Password <span class="text-danger">*</span></label>
                                                <div class="input-group">
                                                    <input placeholder="Password" class="form-control" value={password} type={setType} onChange={(e)=>dispatch(setPassword(e.target.value))}/>

                                                    <button type="button" class="btn btn-light bg-transparent" onClick={handleToggle}>
                                                        <Icon class="absolute mr-10" icon={setIcon} size={15}/>
                                                    </button>

                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <div class="col-sm-6">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value="check" checked={checked && checked} onChange={(e)=>dispatch(setLogCheck(e.target.value))}
                                                            id="flexCheckDefault" />
                                                        <label class="form-check-label" for="flexCheckDefault">
                                                            Keep Me Logged In
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 text-end">
                                                    <a href="#" class="text-main text-decoration-none">Forget Password</a>
                                                </div>
                                            </div>
                                            <div class="mb-3">

             <Link to='/' class="btn btn-main px-4 py-2" onClick={handleLogin}> Log In </Link> 
                            <ToastContainer />

                                              
                                            </div>
                                            <div class="text-center small">
                                                New User? <Link to={'/register'} class="text-main text-decoration-none">Register Here</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           <Footer/>                                             
        </div>
    )
}

export default UserLogin