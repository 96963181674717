import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Index from './Components/Index';
import UserLogin from './Components/UserLogin';
import UserRegistration from './Components/UserRegistration';
import Questions from './Components/Questions';
import SelectCourse from './Components/SelectCourse';
import TrialReview from './Components/TrialReview';

function App() {
  return (
    <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Index/>}></Route>
            <Route path='/login' element={<UserLogin/>}></Route>
            <Route path='/register' element={<UserRegistration/>}></Route>
            <Route path='/mcq/:id' element={<Questions/>}></Route>
            <Route path='/select-course' element={<SelectCourse/>}></Route>
            <Route path='/trial-review' element={<TrialReview/>}></Route>
          </Routes>
        </BrowserRouter>
    </div>
  );
}
export default App;
