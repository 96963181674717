import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { setEmail, setFname, setDOB, setPassword,setPhone,setConfirmPassword ,register} from '../Slice/QuestionSlice';

function UserRegistration() {

    const { fname,dob,email,phone,password,confirmpassword ,formErrors} = useSelector((state) => state.questionOperation);

    const dispatch=useDispatch();

    const handleRegister=()=>{

        var data = {
            fname:fname,
            dob:dob,
            email:email,
            phone:phone,
            password:password
          };
          dispatch(register(data));
    }


  return (
    <div>
         <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12 px-0">
                        <nav class="navbar navbar-expand-lg bgheader">
                            <div class="container">
                                <a class="navbar-brand d-flex align-items-end d-lg-block" href="index.html">
                                    <img src="images/gayatrilogo.png" style={{height:'100px'}} alt="logo" />
                                </a>
                                <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                                    <span class="navbar-toggler-icon"></span>
                                </button>
                                <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar"
                                    aria-labelledby="offcanvasNavbarLabel">
                                    <div class="offcanvas-header">
                                        <h5 class="offcanvas-title" id="offcanvasNavbarLabel">Navigation</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="offcanvas"
                                            aria-label="Close"></button>
                                    </div>


                                    {/* <div class="offcanvas-body">
                                        <ul class="navbar-nav mx-auto">
                                            <li class="nav-item">
                                                <a class="nav-link active" aria-current="page" href="index.html">Home</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" href="about-us.html">About Us</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" href="#">Medical Student</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" href="#">More Specialist</a>
                                            </li>
                                            <li class="nav-item">
                                                <Link to={'/login'} class="nav-link login" >Login</Link>
                                            </li>
                                            <li class="nav-item">
                                                <Link to={'/register'} class="nav-link register" >Register</Link>
                                            </li>
                                        </ul>
                                        <div class="d-flex" role="search">
                                            <div class="input-group">
                                                <span class="input-group-text"><i class="bi bi-search"></i></span>
                                                <input class="form-control me-2" type="search" placeholder="Search"
                                                    aria-label="Search" />
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>


            <div class="container my-5">
                <div class="row align-items-center">
                    <div class="col-sm-4 bg-main p-4 d-none d-lg-block">
                        <img src="images/login.svg" class="img-fluid" alt="Register"/>
                    </div>
                    <div class="col-sm-8 p-4 m-auto">
                        <div class="form-container">
                            <h3 class="mb-3">Register</h3>
                            <div class="row">
                                <div class="col-sm-6 mb-3">
                                    <label class="form-label">Full Name <span class="text-danger">*</span></label>
                                    <input type="text" class="form-control" placeholder="Enter Full Name" onChange={(e)=>dispatch(setFname(e.target.value))}/>
                                </div>
                                <div class="col-sm-6 mb-3">
                                    <label class="form-label">Email ID <span class="text-danger">*</span></label>
                                    <input type="email" class="form-control" placeholder="Email ID" onChange={(e)=>dispatch(setEmail(e.target.value))}/>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6 mb-3">
                                    <label class="form-label">Mobile Number <span
                                        class="text-danger">*</span></label>
                                    <input type="email" class="form-control" placeholder="Mobile Number"  onChange={(e)=>dispatch(setPhone(e.target.value))} />
                                </div>
                                <div class="col-sm-6 mb-3">
                                    <label class="form-label">Date Of Birth<span
                                        class="text-danger">*</span></label>
                                    <input type="date" class="form-control" placeholder="Enter DOB"  onChange={(e)=>dispatch(setDOB(e.target.value))} />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6 mb-3">
                                    <label class="form-label">Password <span class="text-danger">*</span></label>
                                    <div class="input-group">
                                        <input type="password" class="form-control" placeholder="Password"  onChange={(e)=>dispatch(setPassword(e.target.value))}/>
                                        <button class="btn"><i class="bi bi-eye"></i></button>
                                    </div>
                                </div>
                                <div class="col-sm-6 mb-3">
                                    <label class="form-label">Password Confirmation <span
                                        class="text-danger">*</span></label>
                                    <div class="input-group">
                                        <input type="password" class="form-control"
                                            placeholder="Password Confirmation"  onChange={(e)=>dispatch(setConfirmPassword(e.target.value))}/>
                                        <button class="btn"><i class="bi bi-eye"></i></button>
                                    </div>
                                </div>
                            </div>

                            <div class="mb-3">
                                <div class="form-check ">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                        <label class="form-check-label" for="flexCheckDefault">
                                            I agree with the <a href="#" class="text-main">Terms & Conditions</a>
                                        </label>
                                </div>
                            </div>
                            <button type="button" class="btn btn-main" onClick={handleRegister}>Register</button>
                        </div>
                    </div>
                </div>
            </div>




            <footer>
                <div class="container">
                    <div class="row">
                        <div class="col-sm-4 col-6">
                            <h5>Our Courses</h5>
                            <ul>
                                <li><a href="#">Otology</a></li>
                                <li><a href="#">Head and Neck</a></li>
                                <li><a href="#">Rhinology and Facial Plastics</a></li>
                                <li><a href="#">Paediatric ENT</a></li>
                            </ul>
                        </div>
                        <div class="col-sm-4 col-6 text-start text-lg-center">
                            <h5>Company</h5>
                            <ul>
                                <li><a href="index.html">Home</a></li>
                                <li><a href="about-us.html">About Us</a></li>
                                <li><a href="#">Courses</a></li>
                                <li><a href="#">Contact Us</a></li>
                            </ul>
                        </div>
                        <div class="col-sm-4 col-12 text-start text-lg-end">
                            <h5>Get In Touch</h5>
                            <ul>
                                <li><a href="#"><i class="bi bi-telephone"></i>(000) 0000 0000</a></li>
                                <li><a href="#"><i class="bi bi-geo-alt"></i>4517 UK - 37</a></li>
                                <li><a href="#"><i class="bi bi-envelope"></i>info@demo.com</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>

            <div id="footer-bottom">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-6 col-auto">
                            Copyright 2022. All Right Reserved
                        </div>
                        <div class="col-sm-6 text-end col">
                            <a href="#"><i class="bi bi-facebook"></i></a>
                            <a href="#"><i class="bi bi-instagram"></i></a>
                            <a href="#"><i class="bi bi-twitter"></i></a>
                            <a href="#"><i class="bi bi-youtube"></i></a>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default UserRegistration