import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from 'axios';
import {eye} from 'react-icons-kit/feather/eye'
import {eyeOff} from 'react-icons-kit/feather/eyeOff';
import { useDispatch } from 'react-redux';


export const fetchData = createAsyncThunk('fetchData',async (id)=>{
    const response = await axios.post('https://mcq.gayatriinfotech.in/api/quizapp/'+id);
    return response.data;
});

export const fetchQuizCourses = createAsyncThunk('fetchQuizCourses',async ()=>{
    const response = await axios.get('https://mcq.gayatriinfotech.in/api/getQuizCourses');
    return response.data;
});

export const addData = createAsyncThunk('addData',async(data)=>{

    // console.log('Adding Data : ',data)
    const formData = new FormData();
    formData.append('student_id', data.student_id);
    formData.append('course_id', data.course_id);
    formData.append('response',JSON.stringify(data.response));
    formData.append('correct_ans',data.inc);
    formData.append('unattempted', data.unattempted);
    formData.append('incorrect', data.incorrect);
    formData.append('total_questions',data.totalQuestions);
    
    const response = await axios.post('https://mcq.gayatriinfotech.in/api/userinfo',formData);
    return response.data;
});

export const register = createAsyncThunk('register',async(data)=>{
    
    console.log("Register Data : ",data);
    const formData = new FormData();
    formData.append('std_name', data.fname);
    formData.append('std_email', data.email);
    formData.append('std_phoneno', data.phone);
    formData.append('std_dob', data.dob);
    formData.append('std_password', data.password);

    const response = await axios.post('https://mcq.gayatriinfotech.in/api/student-register',formData);
    console.log(response.data)
    return response.data;
});

export const login = createAsyncThunk('login',async(data)=>{

    console.log("Login Data : ",data);

    const formData = new FormData();
    formData.append('std_email', data.email);
    formData.append('std_password', data.password);
    const response = await axios.post('https://mcq.gayatriinfotech.in/api/student-login',formData);
    return response.data;
});

export const userRecords = createAsyncThunk('userRecords',async (id)=>{
    const response = await axios.post('https://mcq.gayatriinfotech.in/api/userRecords/'+id);
    return response.data;
});

const initialState = {
    student_id:'',
    userid:'',
    fname:'',
    dob:'',
    name:'',
    email:'',
    phone:'',
    password:'',
    confirmpassword:'',
    inc:0,
    qid:0,
    attempt:0,
    flag:0,
    show:false,
    correctanswer:'',
    questions:[],
    records:[],
    loading:false,
    userResponse:[],
    loginstatus:'',
    quizCourses:[],
    flag:1,
    checked:false,
    setType:'password',
    setIcon:eyeOff,
    unattempted:'',
    incorrect:0,
    traildata:'',
    time:0,
    progressload:0,
};

const questionOperation = createSlice({
    name:'questionOperation',
    initialState,
    reducers:
    {
        setFname:(state,action)=>{
            state.fname=action.payload;
        },
        setDOB:(state,action)=>{
            state.dob=action.payload;
        },
        setEmail:(state,action)=>{
            state.email=action.payload;
        },
        setPhone:(state,action)=>{
            state.phone=action.payload;
        },
        setPassword:(state,action)=>{
            state.password=action.payload;
        },
        setConfirmPassword:(state,action)=>{
            state.confirmpassword=action.payload;
        },
        nextQue:(state) =>{
            state.qid += 1;
            console.log('qid:'+state.qid);
        },
        setQid: (state,payload) =>{
            state.qid=payload.payload;
        },
        setShow:(state,payload)=>{
            state.show=payload.payload;
        },

        setLogCheck:(state,payload)=>{
            state.checked = !state.checked;
            console.log(state.checked);
        },

        setTypeText:(state)=>{
            state.setType = "text";
            state.setIcon = eye;
        },

        setTypePassword:(state)=>{
            state.setType = "password";
            state.setIcon = eyeOff
        },
        setTrailData:(state,payload)=>{
            state.traildata=payload.payload
        },

        setLoginStatusNull:(state)=>{
            state.loginstatus = '';
        },

        setTime:(state)=>{
            state.time = state.time -1;
        },

        setOptions:(state,payload) =>{
            state.progressload +=100/state.questions.length ;
            const updateQuestions=state.questions && state.questions.map((ques)=>{
                if(ques.id===payload.payload.id)
                {
                    if(ques.answer===payload.payload.userOpt){
                        if(ques.flag===0){
                            state.inc=parseInt(state.inc)+1;
                            ques.flag=1;
                        }   
                    }
                    else
                    {
                        if(ques.flag===1)
                        {
                            state.progressload -=100/state.questions.length ;
                            state.inc=parseInt(state.inc)-1;
                            ques.flag=0;
                        }  
                    }
                    return{...ques,userOpt:payload.payload.userOpt}
                }
                return ques;
            })
            state.questions=updateQuestions;
            console.log(state.questions);
            console.log("Correct Ans",state.inc);
            console.log("Incorrect Ans",state.incorrect);
        },
    },
        extraReducers:{
            [fetchData.pending]:(state,action)=>{
                state.loading=true;
            },
            [fetchData.fulfilled]: (state , action) => {
                    state.loading=false;
                    const questions= action.payload;
                    var srNo = 0;
                    const updateQuestions = questions && questions.map((ques)=>{
                        srNo++;
                        return {...ques,srNo:srNo,userOpt:'',flag:0}
                    })
                    state.questions=updateQuestions;
                    state.time = 60 * (questions.length * 0.05);
                    // console.log(state.time);
            },
            [fetchQuizCourses.pending]:(state,action)=>{
                state.loading=true;
            },
            [fetchQuizCourses.fulfilled]: (state , action) => {
                    state.loading=false;
                    const courses = action.payload;
                    var flag=0;

                    const updatedCourses = courses && courses.map((c)=>{
                        if(flag<4)
                        flag++;
                        else
                        flag=1;
                        return {...c,flag:flag}
                    });

                    state.quizCourses = updatedCourses;
                    console.log(state.quizCourses)
            },

            [addData.pending]:(state)=>{
                state.loading=true;
            },
            [addData.fulfilled]:(state)=>{
                state.loading=false;
                state.qid =0;
                state.inc=0;
                state.incorrect=0;
                state.unattempted=0;
                state.progressload=0;
            },

            [register.pending]:(state)=>{
                state.loading=true;
            },
            [register.fulfilled]:(state)=>{
                state.loading=false;
                alert('Registration Successful !!!');
                window.location="/login";
            },

            [login.pending]:(state)=>{
                state.loading=true;
            },
            [login.fulfilled]:(state,action)=>{
                state.loading=false;
                var result = action.payload;

                console.log("Result data : ",result)

                if(result.status == "Success")
                {
                    state.loginstatus='success';
                    state.password='';

                    const user = {id:result.data.id, name:result.data.std_name};
                    if(state.checked)
                    {
                        localStorage.setItem('user',JSON.stringify(user));
                        console.log('logged using local storage')
                    }
                    else
                    {
                        sessionStorage.setItem('user',JSON.stringify(user));
                        console.log('logged using session storage')
                    }
                }
                else
                {
                    state.password='';
                    state.loginstatus='fail';
                }
            },

            [userRecords.pending]:(state,action)=>{
                state.loading=true;
            },
            [userRecords.fulfilled]: (state , action) => {
                    state.loading=false;
                    state.records=action.payload
            },
    }
});

export const {nextQue,setQuestion,setQues,setQid,setOptions,setCorrectAnswer,setTrailData,setEmail,setName,setPhone,setShow,setFname,setDOB,setPassword,setConfirmPassword,setLogCheck,setTypePassword,setTypeText, setLoginStatusNull,setTime} = questionOperation.actions;

export default questionOperation.reducer;