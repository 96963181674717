import React from 'react'
import Header from './Header'
import { useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShow,setTrailData } from '../Slice/QuestionSlice';
import { Link, useParams } from 'react-router-dom';
import { nextQue, setQid, addData, setOptions, fetchData,setTime } from '../Slice/QuestionSlice';
import { Button, Modal } from 'react-bootstrap';
import { FormLabel } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';

function Questions() {
  const { questions, qid, inc, fname, email, phone, response, show,attempt,time,progressload} = useSelector((state) => state.questionOperation);
  const dispatch = useDispatch();

  console.log(questions)

  const params = useParams();

  const cid = params.id;

  useEffect(()=>{
    dispatch(fetchData(cid));
  },[])

  const localUser = JSON.parse(localStorage.getItem("user"));
  const sessionUser = JSON.parse(sessionStorage.getItem("user"));

    let student_id = '';

    if(localUser && localUser.name!==""){
        student_id = localUser.id;
    }

    else if(sessionUser && sessionUser.name!==""){
        student_id = sessionUser.id;
    }

  const handleSubmit = () => {
     
   let unattemptedCount = 0;

   questions && questions.map((ques)=>{
      if(ques.userOpt===""){
        unattemptedCount++;
      }
   })

   const incorrect = questions.length - unattemptedCount - inc;

    var data = {
      student_id:student_id,
      course_id:cid,
      unattempted:unattemptedCount,
      incorrect:incorrect,
      inc: inc,
      totalQuestions: questions.length,
      response: [],
    };

    questions && questions.map((ques) => {
      data = { ...data, response: [...data.response, { qid: ques.id, userOpt: ques.userOpt }] };
    });

    dispatch(addData(data));

    dispatch(setTrailData(data));

    console.log("UserResponse",data);
  }

  const notAnsweredCount = questions.filter(d => d.userOpt === 0 || d.userOpt === "").length;
  const answeredCount = questions.length - notAnsweredCount;

  var now = 0;
  
  const handleChange = (id) => {
    dispatch(setQid(id - 1));
  }

  if (questions.length === 0) {
    return (
      // <h1>Loading...</h1>
      <div  style={{marginTop:'150px',verticalAlign:'middle'}}>
      <img src="../images/info.gif" className="dashboard-logo" loading='lazy'/>
  </div>
    )
  }

  const handleShow = () => {
    dispatch(setShow(true));
  }

  const handleClose = () => {
    dispatch(setShow(false));
  }


/* ----------------------------------------Timer Section----------------------------------------------------*/
const initialTime = 60 * (questions.length * 0.05); 
const onTimerComplete = () => {
  // alert('Time is up!');
  // useEffect(()=>{
  //   handleShow();
  // },[]);
  
  dispatch(setShow(true));
};

function ExamTimer({ initialTime, onTimerComplete }) {
  // const [time, setTime] = useState(initialTime);

  useEffect(() => {
    let timer;
    if (time > 0) {
      timer = setTimeout(() => {
        dispatch(setTime());
      }, 1000);
    } else {
      onTimerComplete();
    }

    return () => clearTimeout(timer);
  }, [time, onTimerComplete]);

  console.log(time)

  const formattedTime = new Date(time * 1000).toISOString().substr(11, 8);
  return (
    <>
    {formattedTime}
    </>
  );
}

/* ----------------------------------------Timer Section----------------------------------------------------*/


  return (
    <React.Fragment>
        <Header />
        <br/>

      <div class="card-header bg-danger bg-opacity-10 p-3">
        <div class="d-flex justify-content-between align-items-center">
          <div className="m-auto">
            <h6 class="alert-heading text-danger" style={{ fontSize: "25px" }}>
              <i class="bi bi-clock"></i> <ExamTimer initialTime={initialTime} onTimerComplete={onTimerComplete}/>
            </h6>
          </div>
        </div>
      </div>

      <br />
        <div className="body-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="card col-md-3">
                <div className="card-body">
                  <div className="card ">
                    <div className="card-body">

                      {questions.map((e) => (
                        <span key={e.id} className="d-inline">
                          {e.userOpt != '' ? 
                            <button onClick={() => handleChange(e.srNo)} className="btn btn-primary m-1">
                              {e.srNo}
                            </button>
                            :
                            <button onClick={() => handleChange(e.srNo)} className="btn btn-secondary m-1">
                              {e.srNo}
                            </button>
                          }
                        </span>
                      ))}

                </div>
              </div>
                  <br/><br/>  
                  <div>
                      <FormLabel component="legend" className="btn btn-primary m-1"></FormLabel> Attempted
                      <FormLabel component="legend" className="btn btn-secondary m-1"></FormLabel> Not Attempted
                  </div>
            </div>
          </div>

                  <div className="card col-md-9" style={{ float:'right' }}>
                    <div className="card-body" style={{ textAlign: "left" }}>
                      <h3 style={{ float: 'left' }}>{qid + 1}. {questions[qid].question}</h3><br /><br /><br></br>

                      <div className="mb-3" style={{ display: JSON.parse(questions[qid].options).opt1 ? 'block' : 'none' }}>
                        <input className="form-check-input" name='option' type="radio" value={JSON.parse(questions[qid].options).opt1} checked={questions[qid].userOpt === JSON.parse(questions[qid].options).opt1} onChange={(e) => dispatch(setOptions({ id: questions[qid].id, userOpt: e.target.value }))} />
                        <label className="form-check-label">&nbsp; {JSON.parse(questions[qid].options).opt1}</label>
                      </div>

                      <div className="mb-3" style={{ display: JSON.parse(questions[qid].options).opt2 ? 'block' : 'none' }}>
                        <input className="form-check-input" name='option' type="radio" value={JSON.parse(questions[qid].options).opt2}
                          checked={questions[qid].userOpt === JSON.parse(questions[qid].options).opt2} onChange={(e) => dispatch(setOptions({ id: questions[qid].id, userOpt: e.target.value }))} />
                        <label className="form-check-label">&nbsp; {JSON.parse(questions[qid].options).opt2}</label>
                      </div>

                      <div className="mb-3" style={{ display: JSON.parse(questions[qid].options).opt3 ? 'block' : 'none' }}>
                        <input className="form-check-input" name='option' type="radio" value={JSON.parse(questions[qid].options).opt3}
                          checked={questions[qid].userOpt === JSON.parse(questions[qid].options).opt3} onChange={(e) => dispatch(setOptions({ id: questions[qid].id, userOpt: e.target.value }))} />
                        <label className="form-check-label">&nbsp; {JSON.parse(questions[qid].options).opt3}</label>
                      </div>

                      <div className="mb-3" style={{ display: JSON.parse(questions[qid].options).opt4 ? 'block' : 'none' }}>
                        <input className="form-check-input" name='option' type="radio" value={JSON.parse(questions[qid].options).opt4}
                          checked={questions[qid].userOpt === JSON.parse(questions[qid].options).opt4} onChange={(e) => dispatch(setOptions({ id: questions[qid].id, userOpt: e.target.value }))} />
                        <label className="form-check-label">&nbsp; {JSON.parse(questions[qid].options).opt4}</label>
                      </div>

                      <br />
                      <br />

                      <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                          <Modal.Title>Exam Review</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                          <div className="App">
                            <div className='container'>
                              <div className='row'>
                                <div className='col-12 m-auto border shadow p-4'>
                                  <div style={{ display: 'inline-block', marginRight: '20px' }}>
                                    <span className='btn btn-success'>{answeredCount}</span>
                                    <h6 className='mt-2'>Attempted</h6>
                                  </div>
                                  <div style={{ display: 'inline-block' }}>
                                    <span className='btn btn-danger'>{notAnsweredCount}</span>
                                    <h6 className='mt-2'>Not Attempted</h6>
                                  </div>
                                  <br />
                                  <br />
                                </div>
                              </div>
                            </div>
                            <br />
                            <h7 style={{ textAlign: "center", color: "blue" }}>Press the submit button to end test!</h7>
                          </div>

                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={handleClose}>
                            Return To Exam
                          </Button>

                          <Link to="/trial-review" onClick={handleClose}>
                            <button style={{ float: "right" }} className='btn btn-success' onClick={handleSubmit}>Submit</button>
                          </Link>
                        </Modal.Footer>
                      </Modal>

                      {/* <div>
                        {questions[qid].id === questions.length ? <Link to='/result' className="btn btn-success " style={{ float: 'right' }} onClick={handleSubmit}>Submit</Link> : <button type="button" className="btn btn-warning" onClick={() => {
                          dispatch(nextQue());
                        }}>Next Question</button>}

                      </div> */}

                      <div>
                        {questions[qid].srNo === questions.length ?
                          <button onClick={handleShow} className="btn btn-success">Submit</button>
                          :
                          <button type="button" className="btn btn-warning" onClick={() => {
                            dispatch(nextQue());
                          }}>Next Question</button>
                        }
                      </div>
                    </div>
                 </div>
                 <div>
                 <ProgressBar now={progressload} label={`${progressload}%`} striped variant={progressload <50 ? "danger" : "success"}   />
                 </div>
              </div>
            </div>
          </div>
    </React.Fragment>
  )
}

export default Questions

