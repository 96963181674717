import React, { useEffect, useState } from 'react'
import Header from './Header'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchData, fetchQuizCourses } from '../Slice/QuestionSlice';
const SelectCourse = () => {
    const { questions, qid, inc, name, email, phone, response, show,attempt, quizCourses} = useSelector((state) => state.questionOperation);
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(fetchQuizCourses());
    },[]);

    // console.log(quizCourses)

  return (
    <>
      <Header/>
      <div class="container" id="wrapper-content">
                <div class="row" id="demo-version">
                    <div class="col-sm-12">
                        <div class="card">
                            <div class="card-header bg-warning bg-opacity-10 p-3">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div className='m-auto'>
                                        <h6 class="alert-heading text-warning" style={{ fontSize:"25px" }}>
                                            <i class="bi bi-check2-square"></i> Select The Course For Quiz
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                        <div>
                                            <div class="card plaincard h-100">
                                                <div class="row mt-3">
                                                    {quizCourses && quizCourses.map((q)=>(
                                                        <div className='col-md-3'>
                                                        <Link to={`/mcq/${q.id}`}
                                                        class={q.flag===1?" card card-body mb-3 border-3 bg-success bg-opacity-10 text-success ":q.flag===2?"card card-body mb-3 border-3 bg-info bg-opacity-10 text-info ":q.flag===3?"card card-body mb-3 border-3 bg-danger bg-opacity-10 text-danger":"card card-body mb-3 border-3 bg-primary bg-opacity-10 text-primary "} style={{ margin:"0 30px" }}>
                                                            <div>
                                                                <div className=" justify-content-between align-items-center">
                                                                    <h2 className="mb-0">{q.course_name}</h2>
                                                                </div>   
                                                            </div>
                                                        </Link>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    </>
  )
}

export default SelectCourse