import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function Header() {
  const { fname } = useSelector((state) => state.questionOperation);

  const handleLocal = () => {
    localStorage.clear();
  };

  const handleSession = () => {
    sessionStorage.clear();
  };


  const localUser = JSON.parse(localStorage.getItem("user"));

  const sessionUser = JSON.parse(sessionStorage.getItem("user"));

  return (
    <nav class="navbar navbar-expand-lg bgheader" id="topbar">
      <div class="container">
        <div class="d-flex w-100">
          <div class="flex-shrink-0">
            <img src="../images/gayatrilogo.png" class="dashboard-logo" />
          </div>
          <div class="flex-grow-1 ms-4">
            
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav me-auto mb-2 mt-4 mb-lg-0">
                <li class="nav-item">
                  <Link to="/" class="nav-link text-light" aria-current="page">
                    Dashboard
                  </Link>
                </li>
                <li class="nav-item dropdown">
                  <a
                    class="nav-link text-light dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Quiz
                  </a>
                  <ul class="dropdown-menu shadow">
                    <li>
                      <a class="dropdown-item" href="/select-course">
                        New Custom Quiz
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/select-course">
                        Quick Start Quiz
                      </a>
                    </li>
                    <li>
                      <hr class="dropdown-divider" />
                    </li>
                    <li>
                      <a class="dropdown-item" href="quizzes.html">
                        My Quizzes
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="nav-item dropdown">
                  <a
                    class="nav-link text-light dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Performance
                  </a>
                  <ul class="dropdown-menu shadow">
                    <li>
                      <a class="dropdown-item" href="performance-timeline.html" >
                        Performance Timeline
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="performance.html">
                        Performance By Subject
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            
              <div class="col-sm-auto">
                <div class="d-flex" id="avatar">
                <div class="dropdown">
                    <button
                      class="btn dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {localUser ? (
                        localUser && localUser.name !== "" ? (
                          <label className="text-light" style={{ fontWeight:'bold',fontSize:'16px' }}> {localUser.name}</label>
                        ) : (
                          (window.location.href = "/login")
                        )
                      ) : sessionUser && sessionUser.name !== "" ? (
                        <label className="text-light" style={{ fontWeight:'bold',fontSize:'16px' }}> {sessionUser.name}</label>
                        ) : (
                        (window.location.href = "/login")
                      )
                      }
                    </button>
                    <ul class="dropdown-menu shadow">
                      <li>
                        <a class="dropdown-item" href="settings.html">
                          <i class="bi bi-person me-3"></i>Profile
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="settings.html">
                          <i class="bi bi-archive me-3"></i>My Products
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="settings.html">
                          <i class="bi bi-unlock me-3"></i>Reset Password
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="settings.html">
                          <i class="bi bi-key me-3"></i>Access Codes
                        </a>
                      </li>
                      <li>
                        {localUser && localUser.name 
                        ?
                        <Link to="/login" onClick={handleLocal} class="dropdown-item">
                         <i class="bi bi-arrow-right me-3"></i>Logout
                        </Link>
                        :
                        <Link to="/login" onClick={handleSession} class="dropdown-item">
                        <i class="bi bi-arrow-right me-3"></i>Logout
                       </Link>
                        }
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Header;
