import React from "react";
import Header from "./Header";
import { useDispatch, useSelector } from "react-redux";
import { setQid } from "../Slice/QuestionSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes,faCheck } from "@fortawesome/free-solid-svg-icons";
import { Pie} from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, RadialLinearScale, PointElement, LineController, LineElement, LinearScale, BarElement } from "chart.js";
import { CategoryScale } from 'chart.js'; 
import { FormLabel } from 'react-bootstrap';


function TrialReview() {
  const { questions, qid ,traildata} = useSelector((state) => state.questionOperation);
  const dispatch = useDispatch();

  const handleChange = (id) => {
    dispatch(setQid(id - 1));
  };

  if (questions.length === 0) {
    return (window.location.href = "/questions");
  }

  console.log("Trail Data : ",traildata)

  const data = {
    labels: [
      'Correct',
      'Incorrect',
      'Unattempted'
    ],
    datasets: [{
      label: 'My First Dataset',
      data: [
          traildata.inc,
          traildata.incorrect,
          traildata.unattempted
      ],
      backgroundColor: [
        'rgb(51, 167, 14)',
        'rgb(255, 5, 5)',
        'rgb(255, 193, 7)'
      ],
      hoverOffset: 4
    }]
  };

  const config = {
    type: 'pie',
    data: data,
  };


    ChartJS.register(ArcElement,CategoryScale,BarElement,Tooltip,Legend,RadialLinearScale,PointElement,LineController, LineElement, PointElement, LinearScale);

  return (
    <React.Fragment>
      <Header />
      <br />

      <div class="card-header bg-success bg-opacity-10 p-3">
        <div class="d-flex justify-content-between align-items-center">
          <div className="m-auto">
            <h6 class="alert-heading text-success" style={{ fontSize: "25px" }}>
              <i class="bi bi-check2-square"></i> Review Page
            </h6>
          </div>
        </div>
      </div>
      <br />

      <div className="body-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="card col-md-3">
              <div className="card-body">
                <div className="card ">
                  <div className="card-body">
                    
                {questions.map((e) => (
                    <span key={e.id} className="d-inline">
                      {e.userOpt === e.answer ?
                      <button onClick={()=>handleChange(e.srNo)} className="btn btn-success  m-1">
                      {e.srNo}
                      </button>
                      :e.userOpt === ""? <button onClick={()=>handleChange(e.srNo)} className="btn btn-warning m-1">{e.srNo}</button> :
                      <button onClick={()=>handleChange(e.srNo)} className="btn btn-danger m-1">
                      {e.srNo}
                      </button>
                      }
                    </span>
                ))}

                  </div>
                </div>
                <br/><br/>

                  <div>
                      <FormLabel className="btn btn-success m-2"></FormLabel> Correct
                      <FormLabel className="btn btn-danger m-2"></FormLabel> Incorrect<br/>
                      <FormLabel className="btn btn-warning m-2"></FormLabel> Not Attempted
                  </div>
                  
              </div>
            </div>

            <div className="card col-md-9" style={{ float: "right" }}>
              <div className="card-body" style={{ textAlign: "left" }}>
                <h3 style={{ textAlign: "left" }}>
                  {qid + 1}. {questions[qid].question}
                </h3>
                <br />
                <br />

                <div
                  className="mb-3"
                  style={{
                    display: JSON.parse(questions[qid].options).opt1
                      ? "block"
                      : "none",
                    color:
                      JSON.parse(questions[qid].options).opt1 ===
                      questions[qid].answer
                        ? "green"
                        : "",

                    fontWeight:
                    JSON.parse(questions[qid].options).opt1 ===
                    questions[qid].answer
                      ? "bold"
                      : "normal",
                  }}
                >
                  <input
                    className="form-check-input"
                    name="option"
                    type="radio"
                    value={JSON.parse(questions[qid].options).opt1}
                    checked={
                      questions[qid].userOpt ===
                      JSON.parse(questions[qid].options).opt1
                    }
                  />
                  <label className="form-check-label">
                    &nbsp; {JSON.parse(questions[qid].options).opt1}&nbsp;&nbsp;
                    
                    {
                       JSON.parse(questions[qid].options).opt1 === questions[qid].answer ?
                       <FontAwesomeIcon icon={faCheck} style={{ color:'green' }} size="1x" />
                       :JSON.parse(questions[qid].options).opt1 === questions[qid].userOpt ?
                       <FontAwesomeIcon icon={faTimes} style={{ color:'red' }} size="1x" />:''
                 
                    }

                  </label>
                </div>
                <div
                  className="mb-3"
                  style={{
                    display: JSON.parse(questions[qid].options).opt2
                      ? "block"
                      : "none",

                    color:
                      JSON.parse(questions[qid].options).opt2 ===
                      questions[qid].answer
                        ? "green"
                        : "",

                    fontWeight:
                    JSON.parse(questions[qid].options).opt2 ===
                    questions[qid].answer
                      ? "bold"
                      : "normal",
                  }}
                >
                  <input
                    className="form-check-input"
                    name="option"
                    type="radio"
                    value={JSON.parse(questions[qid].options).opt2}
                    checked={
                      questions[qid].userOpt ===
                      JSON.parse(questions[qid].options).opt2
                    }
                  />
                  <label className="form-check-label">
                    &nbsp; {JSON.parse(questions[qid].options).opt2}&nbsp;&nbsp;
                    {
                       JSON.parse(questions[qid].options).opt2 === questions[qid].answer ?
                       <FontAwesomeIcon icon={faCheck} style={{ color:'green' }} size="1x" />
                       :JSON.parse(questions[qid].options).opt2 === questions[qid].userOpt ?
                       <FontAwesomeIcon icon={faTimes} style={{ color:'red' }} size="1x" />:''
                     
                    }


                  </label>

              
                </div>

                <div
                  className="mb-3"
                  style={{
                    display: JSON.parse(questions[qid].options).opt3
                      ? "block"
                      : "none",
                    color:
                      JSON.parse(questions[qid].options).opt3 ===
                      questions[qid].answer
                        ? "green"
                        : "",

                    fontWeight:
                    JSON.parse(questions[qid].options).opt3 ===
                    questions[qid].answer
                      ? "bold"
                      : "normal",
                  }}
                >
                  <input
                    className="form-check-input"
                    name="option"
                    type="radio"
                    value={JSON.parse(questions[qid].options).opt3}
                    checked={
                      questions[qid].userOpt ===
                      JSON.parse(questions[qid].options).opt3
                    }
                  />
                  <label className="form-check-label">
                    &nbsp; {JSON.parse(questions[qid].options).opt3}&nbsp;&nbsp;

                    {
                      JSON.parse(questions[qid].options).opt3 === questions[qid].answer 
                      ?
                      <FontAwesomeIcon icon={faCheck} style={{ color:'green' }} size="1x" />
                      :
                      JSON.parse(questions[qid].options).opt3 === questions[qid].userOpt 
                      ?
                      <FontAwesomeIcon icon={faTimes} style={{ color:'red' }} size="1x" />
                      :
                      ''
                  
                    }
                  </label>
                </div>
                <div
                  className="mb-3"
                  style={{
                    display: JSON.parse(questions[qid].options).opt4
                      ? "block"
                      : "none",
                    color:
                      JSON.parse(questions[qid].options).opt4 ===
                      questions[qid].answer
                        ? "green"
                        : "",

                    fontWeight:
                    JSON.parse(questions[qid].options).opt4 ===
                    questions[qid].answer
                      ? "bold"
                      : "normal",
                  }}
                >
                  <input
                    className="form-check-input"
                    name="option"
                    type="radio"
                    value={JSON.parse(questions[qid].options).opt4}
                    checked={
                      questions[qid].userOpt ===
                      JSON.parse(questions[qid].options).opt4
                    }
                  />
                  <label className="form-check-label">
                    &nbsp; {JSON.parse(questions[qid].options).opt4}&nbsp;&nbsp;

                    {
                      JSON.parse(questions[qid].options).opt4 === questions[qid].answer ?
                      <FontAwesomeIcon icon={faCheck} style={{ color:'green' }} size="1x" />
                      :JSON.parse(questions[qid].options).opt4 === questions[qid].userOpt ?
                      <FontAwesomeIcon icon={faTimes} style={{ color:'red' }} size="1x" />:''
                  
                    }
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="col-md-6 m-auto">
          <div class="card h-100 plaincard">
              <div class="card-header text-muted">
                  Result
              </div>
              <div class="card-body">
                  <div class="row gx-1">
                      <div class="col-sm-4 m-auto">
                      <Pie data={data} options={config}/> 
                      </div>
                  </div>
              </div>
          </div>
      </div>

    </React.Fragment>
  );
}

export default TrialReview;
